import React from "react";
import "./BlogDetailPart1.css";
import images from "../../constants/images";

const BlogDetailPart3 = () => {
  return (
    <div className="main_wrapp">
      <div>
        <div className="bd_img_wrapp">
        <img src={images.blog_img3_banner} alt="blog_detail_img" className="blog_detail_large_img"/>
          <img src={images.blog_img3_mob} alt="blog_detail_img_mob" className="blog_detail_mob_img" />
        </div>
        <div className="bd_first_blue_wrapp">
          <span>March 4, 2024</span>
          <h1>
          Conquering the Interview: Essential Tips for Nailing Your U.S. Internship
          </h1>
        </div>
        <div className="bd_details_main_wrapp">
          <p className="bd_details_first_paregraph">
          Landing a U.S. internship interview is a fantastic achievement. Now comes the next hurdle: acing the interview itself. Here's your ultimate guide to preparing for U.S. internship interviews, packed with tips and tricks to make a strong impression and secure your dream internship.
          </p>
          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
            Research is Key:
          </h2>
          <h3 className="bd_details_secound_title">Company Knowledge:</h3>
          <p className="bd_details_secound_paregraph">
          Deeply research the company and the specific internship and position you're applying for. Understand their mission, values, and current projects. Show genuine interest and how your skills align with their needs.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Interviewers' Backgrounds:</h3>
          <p className="bd_details_secound_paregraph">
          If possible, research your interviewers beforehand. This helps you tailor your responses and showcase your knowledge of their areas of expertise.
          <br />
          </p>

          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
           Practice Makes Perfect:
          </h2>
          <h3 className="bd_details_secound_title">Mock Interviews:</h3>
          <p className="bd_details_secound_paregraph">
          Practice your interview skills with friends, family, or even career counseling services. Conduct mock interviews focusing on common internship interview questions.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Prepare Answers:</h3>
          <p className="bd_details_secound_paregraph">
          Anticipate common interview questions like "Tell me about yourself" or "Why are you interested in this internship?" Craft clear and concise answers that highlight your strengths and experiences.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Practice Behavioral Questions:</h3>
          <p className="bd_details_secound_paregraph">
          Behavioral interview questions ask how you handled specific situations in the past. Use the STAR method (Situation, Task, Action, Result) to structure your responses effectively.
          <br />
          </p>

          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
           Be Confident and Professional:
          </h2>
          <h3 className="bd_details_secound_title">First Impressions Matter:</h3>
          <p className="bd_details_secound_paregraph">
          Make a Strong Virtual Impression! Dress professionally for your video interview and ensure you're in a well-lit, quiet space. Arrive online a few minutes early to test your technology and avoid any last-minute glitches. Maintain eye contact with the camera, and show a positive and enthusiastic demeanor throughout the interview.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Articulate Clearly:</h3>
          <p className="bd_details_secound_paregraph">
          Speak clearly and concisely. Avoid filler words like "um" or "like". Use strong vocabulary and demonstrate your communication skills.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Prepare Questions:</h3>
          <p className="bd_details_secound_paregraph">
          Have a few well-thought-out questions to ask the interviewer about the internship and the company culture. This shows your genuine interest and initiative.
          <br />
          </p>

          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
           Be Yourself and Show Enthusiasm:
          </h2>
          <h3 className="bd_details_secound_title">Let Your Personality Shine:</h3>
          <p className="bd_details_secound_paregraph">
          Don't be afraid to show your personality and enthusiasm for the opportunity. However, maintain professionalism throughout the interview.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Highlight Your Passion:</h3>
          <p className="bd_details_secound_paregraph">
          Discuss your academic interests and career aspirations. Show genuine enthusiasm for the internship and the field you're entering.
          <br />
          </p>

          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
          Follow Up:
          </h2>
          {/* <h3 className="bd_details_secound_title">Thank You Note: </h3> */}
          <p className="bd_details_secound_paregraph">
          Followup immediately after the interview by sending a thankyou email to your interviewer. Reiterate your interest in the internship and mention any key points discussed during the interview.
          <br />
          </p>
          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
          Bonus Tip:
          </h2>
          <p className="bd_details_secound_paregraph pbb1" >
          Discuss your academic interests and career aspirations. Show genuine enthusiasm for the internship and the field you're entering.
         
          </p>
          {/* <br /> */}
          {/* <br /> */}
          <p className="bd_bold_question">By following these tips and thoroughly preparing yourself, you'll be well-equipped to impress your interviewers and land your dream U.S. internship! Remember that confidence, enthusiasm, and showcasing the value you bring are key to succeeding in your U.S. internship interview.
        </p>
        </div>
        <div className="bd_secound_blue_box_wrapp">
          <div className="bd_secound_blue_first_part">
            <img src={images.navlogo} style={{ objectFit: "contain" }} alt="navlogo" />
            <h3>Team USAIntern</h3>
            {/* <a href="">View Posts</a> */}
          </div>
          <div className="bd_secound_blue_secound_part" />
          <div className="bd_secound_blue_third_part">
            <p>Post Tags:</p>
            <div>
              <a href="#">Florida Travel</a>
              <a href="#">Hidden Gems</a>
              <a href="#">Tourist Attractions</a>
              <a href="#">Local Destinations</a>
              <a href="#">Travel Guide</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailPart3;
