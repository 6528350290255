import React, { useEffect, useState } from 'react'
import Homecultural from '../container/homeCulturalprgm/Homecultural'
import Programbanner2 from '../container/programBanner2/Programbanner2';
import ProgramDescription from '../container/programDescription/ProgramDescription';
import images from '../constants/images';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HashLink as Link1 } from "react-router-hash-link";
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../GlobalStateContext';

const data = [
    {
      id: 1,
      name: "Program Description",
    },
    {
      id: 2,
      name: "Eligibility",
    },
    {
      id: 3,
      name: "Fields",
    },
    {
      id: 4,
      name: "Why Choose usintern.club",
    },
    {
      id: 5,
      name: "Program Options",
    },
    {
      id: 6,
      name: "Compensation",
    },
    {
      id: 7,
      name: "Housing",
    },
    {
      id: 8,
      name: "Fees",
    },
    {
      id: 9,
      name: "Application Process",
    },
  ];
const AllProgram2 = () => {
  const{t} = useTranslation();
    const eligibility = [
        {
          id: 1,
          eligibility:
            "Must have completed a degree or professional certificate at a higher education insitution outside the U.S. and have one year related work experience outside the U.S.",
        },
        {
          id: 2,
          eligibility:
            "If no degree, have at least five years of related work experience",
        },
        {
          id: 3,
          eligibility:
            "Verifiable English Language skills, e.g. Language Exam, etc.",
        },
        {
          id: 4,
          eligibility: "Pass the interview conducted with our agency (checking English level and eligibility)",
        },
        {
          id: 5,
          eligibility:
            "Valid Passport",
        },
        {
            id: 6,
            eligibility:
              "Program duration is maximum 18 months, no extensions are possible",
          },
      ];
      const fields_of_internshp = [
        { id:1, field: "Marketing and Communications" },
        { id:2,field: "Tourism" },
        { id:3,field: "IT and Computers" },
        { id:4,field: "Sales" },
        { id:5,field: "Finance and Accounting" },
        { id:6,field: "Agriculture" },
        { id:7,field: "Business Management" },
        { id:8,field: "Logistics and Distribution" },
        { id:9,field: "Web design" },
        { id:10,field: "Graphic Design" },
        { id:11,field: "Human Resources" },
        { id:12,field: "Hospitality/Culinary" },
        { id:13,field: "Media and Entertainment" },
        { id:14,field: "Internet Marketing" },
      ];
      
    
      const location = useLocation();
      const cardDetails = location.state?.cardDetails || {};
      const navigate = useNavigate();
      const handleApply = () => {
        navigate("/contactus");
      };


    const [getindex, setIndex] = useState(0);
  const handleLinkClick = (link, index) => {
    // setSelectedLink(link);
    setIndex(index);

    // Scroll to the corresponding section
    const element = document.getElementById(link);
    if (element) {
      let yOffset;
      if (window.matchMedia("(max-width: 363px)").matches) {
        yOffset = 350; // Offset for smaller screens
      }
      else if (window.matchMedia("(max-width: 498px)").matches) {
        yOffset = 300; // Offset for smaller screens
      }
      else if (window.matchMedia("(max-width: 647px)").matches) {
        yOffset = 230; // Offset for smaller screens
      }
      else if (window.matchMedia("(max-width: 1085px)").matches) {
        yOffset = 180; // Offset for smaller screens
      } 
      else {
        yOffset = 180; // Offset for larger screens
      }
      const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
      window.scrollTo({ top: y, behavior: 'instant' });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.programdes_wrap');
      let currentIndex = 0;
      const scrollPosition = window.scrollY;

      const navbarHeight = 84.2; // Adjust the selector as per your actual class name
      const proNavBaseHeight = document.querySelector('.pro_nav_base_wrapp').offsetHeight;
    
      // Calculate the total offset
      const yOffset = navbarHeight + proNavBaseHeight;
  
      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop - yOffset; // Adjusted offset for better UI
        const sectionBottom = sectionTop + section.offsetHeight;
  
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentIndex = index;
        }
      });
  
      setIndex(currentIndex);
    };
  
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate1 = useNavigate();
  const handleCardClick1 = () => {
      // Navigate to the desired route
      navigate1('/InternshipUSA');
    };
    const handleCardClick2 = () => {
      // Navigate1 to the desired route
      navigate1('/ProfessionalCareerTrainingUSA');
    };
    const handleCardClick3 = () => {
      // Navigate1 to the desired route
      navigate1('/VirtualInternship');
    };
    const handleCardClick4 = () => {
      // Navigate1 to the desired route
      navigate1('/STEPUSA');
    };
    const handleCardClick5 = () => {
      // Navigate1 to the desired route
      navigate1('/AuPairProgram');
    };
  

    const { setScrollPosition } = useGlobalState();
    const handleApplyNowClick = () => {
      setScrollPosition(1);
    };
  return (
    <div>
        <div>
            <div className="program2_main_wrapp2">
           {/* <img src={images.program_banner2} alt="" /> */}
                <div className='program-title'>
                    <p>{t("PROFESSIONAL CAREER TRAINING")}</p>
                </div>
            </div>
        </div>
        <div className="pro_nav_main_wrapp">
        <div className="container">
          <div className="pro_nav_base_wrapp">
            <div className="pro_nav_links2">
              {data && data.length > 0
                ? data.map((item, index) => {
                    return (
                      <button
                        className={getindex === index ? "selected-link" : ""}
                        onClick={() => handleLinkClick(item.id, index)}
                      >
                        {t(item.name)}
                      </button>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div id='programdescription'>
      <div className="programdes-main">
      <div className="programdes_wrap" id="1">
        <p className="pro_des_title">{t("Program Description")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>{t("#workexperience #USAtraining #careers")}
            </p>
          <br />
          <p>
            {t("ARE YOU READY TO GET A LIFETIME EXPERIENCE AND STAND OUT FROM THE CROWD?")}
          </p>
          <br />
          <p>
          {t("Through our Training Program, you can acquire professional experience in the USA for up to 18 months. An international position is not only one of the best ways to gain valuable professional experience abroad but you also get to know other cultures and make international friends.")}
          </p>
          <br />
          <p>
          {t("This program will give you a competitive edge, since you will learn international best practices, get hands-on coaching from American managers and most likely a good reference letter from an American company.")}
          </p>
        </div>
      </div>
      <div className="programdes_wrap" id="2">
        <p className="pro_des_title">{t("Eligibility")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="eligibility-list">
          {eligibility.map((list) => {
            return (
              <React.Fragment>
                <li className='eligibility-list-list1'>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {t(list.eligibility)}
                </li>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <div className="programdes_wrap" id="3">
        <p className="pro_des_title">{t("Fields")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="grid-container">
          {fields_of_internshp.map((items) => {
            return (
              <div className="grid-item">
                <img src={images.green_tick} alt="green-tick" />
                {t(items.field)}
              </div>
            );
          })}
        </div>
      </div>

      <div className="programdes_wrap" id="4">
        <p className="pro_des_title">{t("Why Choose usintern.club")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="fee-list">
          <ul>
            <li>
              {t("Expert guidance : Empowering your journey with our seasoned experts, every step of the way.")}
            </li>
            <li>
              {t("Transparent process : Clear and concise communication, because transparency matters.")}
            </li>
            <li>{t("Preparation and readiness : Unlocking your potential with expert interview preparation and training, to land your ideal internship.")}</li>
            <li>{t("Success stories : Proven track record of success, with stories to inspire and motivate.")}</li>
            <li>{t("Cultural integration : Immersing you in the vibrant US culture, fostering personal growth and cultural emergence, as you live, learn, and thrive in America.")}</li>
            <li>{t("Continuous support : Support that doesn't end; from application to reaching the destination and beyond.")}</li>
          </ul>
        </div>
      </div>

      <div className="programdes_wrap" id="5">
        <p className="pro_des_title">{t("Program Options")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>
            <span className="underlinepro">{t("Independent Program")}</span>  {t("If you already found a position in the USA on your own, we will help you with the visa process.")}
          </p>
          <p>
            <span className="underlinepro">{t("Full Program")}</span> {t("We will find a relevant position in the USA for you and also help you with the visa process.")}
          </p>
        </div>
      </div>

      {/* Compensation */}
      <div className="programdes_wrap" id="6">
        <p className="pro_des_title">{t("Compensation")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>
          {t("Most of our positions are paid. The compensation plan varies from company to company.")}

          </p>
        </div>
      </div>

      {/* Housing */}
      <div className="programdes_wrap" id="7">
        <p className="pro_des_title">{t("Housing")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="pro_des_content">
          <p>
          {t("The best way to get to know the American culture is to live with a Host Family. We can place you at a host family if you decide to go with this option.")}
          </p>
          <br />
          <p>
          {t("However, if you prefer to find housing independently, you can either find an apartment on your own or choose to stay in a hostel/hotel/airbnb for the first couple of days and decide after your arrival which apartment to choose.")}
          </p>
        </div>
      </div>

      {/* Fees */}
      <div className="programdes_wrap" id="8">
        <p className="pro_des_title">{t("Fees")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="fee-list">
          <ul>
            <li>
              {t("Program consultation: FREE (Determine eligibility, Choose the right program etc.)")}
            </li>
            <li>
            {t("Program fee (including DS 2019 and Training Plan) – between $1850 – $3450")}
            </li>
            <li>{t("US Consulate Visa fee – $185")}</li>
            <li>{t("SEVIS fee – $220")}</li>
            <li>{t("Insurance – $65/month")}</li>
          </ul>
        </div>
      </div>

      {/* Application Process */}
      <div className="programdes_wrap" id="9">
        <p className="pro_des_title">{t("Application Process")}</p>
        <br />
        <hr className="head-bottom" />
        <br />
        <div className="application-list">
          {/* {Application_process.map((list) => {
            return ( */}
              <ul>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Register through our website free of charge by clicking")} <Link 
                      to="/contactus" 
                      state={{scroll: 'true'}}
                      onClick={handleApplyNowClick}
                      // smooth 
                      // to="/#homegetintouch"
                      // scroll={(el) => {
                      //   const offset = 84.2; // Set your desired offset here
                      //   const elementPosition = el.getBoundingClientRect().top;
                      //   const offsetPosition = elementPosition - offset;
                      
                      //   window.scrollTo({
                      //     top: offsetPosition,
                      //     behavior: "instant",
                      //     // duration:100,
                      //   });
                      // }}  
                      className='app-link'>{t("here")}
                      </Link> {t("or by clicking the APPLY NOW button from the menu")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("USAintern.club will contact you personally and arrange a video interview with you where we determine your eligibility and discuss our Program options with you.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("If you are eligible and decide to participate in one of our great programs by accepting the Program Terms and Conditions, we will email you a detailed program description along with CV and Cover Letter templates.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("We will put your file together from the documents acquired from you: your application survey, cover letter, CV, etc.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Once we are ready preparing your documents, we will begin the placement process: we will choose all relevant open positions from our current database and send out your CV and Cover Letter to the chosen companies in the USA and keep you informed during the process.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Once a match is found, we will prepare you for the interview with the company(s). We will schedule the interview for you.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Once you are offered a position by one of the companies, we will start the J1 visa process")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("The Visa Sponsor will issue the DS 2019 Document and the Training Plan.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("When you have the necessary documents ready, you can schedule a visa interview with the U.S. Embassy. You will need to pay the visa fee to the Embassy directly..")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("After you pass the Visa Interview and your Visa is issued, a pre-travel orientation will take place to prepare you for the best year in your life!")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1"className='list-icon' />
                  {/* {list.process} */}
                  <p>{t("If you choose to use our Housing Service, we will choose the right Host Family for you. If you need to find an apartment for yourself or book a hostel, this is the time to do it.")}</p>
                </li>
                <li>
                  <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                  {/* {list.process} */}
                  <p>{t("Please book your flight only after you get your J1 Visa, since most of the flight tickets are non-refundable. We can help you book your flight through our partner travel agency.")}</p>
                </li>
              </ul>
            {/* );
          })} */}
        </div>
      </div>
      <div className="apply-btn-div">
      <Link 
      to="/contactus" 
      state={{scroll: 'true'}}
      onClick={handleApplyNowClick}
        // smooth 
        // to="/#homegetintouch"
        // scroll={(el) => {
        //   const offset = 84.2; // Set your desired offset here
        //   const elementPosition = el.getBoundingClientRect().top;
        //   const offsetPosition = elementPosition - offset;
        
        //   window.scrollTo({
        //     top: offsetPosition,
        //     behavior: "instant",
        //     // duration:100,
        //   });
        // }} 
        className="apply-btn-new"
        >
        {t("apply now")}
        </Link>
      </div>
    </div>
    <div className='checkout_program'>
      <div className='home-section2_new'>
        <div className='home-sec2-content'>
          <div className='h-sec2-head'>
            <div ></div>
            <p className='h-checkout-text'>{t("Check Out Our Other Programs")}</p>
          </div>
          <div className='sec2-cards-div'>
            {/* internship usa card */}
            <div className='sec2-card-new' onClick={handleCardClick1}>
              <img src={images.sec2_card_img1} alt="" />
              <div className='sec2-card-del'>
                <p className='p-title'><span>{t('internship usa')}</span></p>
                <p className='p-des'>{t('J1 Visa Program for current...')}</p>
                <p className='full-details'>{t('J1 Visa Program for current university students or recent graduates. Get an internship in your own field!')}</p> 
              </div>
            </div>

            {/* AU Pair usa card */}
            <div className='sec2-card-new' onClick={handleCardClick5}>
              <img src={images.sec2_card_img5} alt="" />
              <div className='sec2-card-del'>
                <p className='p-title'><span>{t("au pair usa")}</span></p>
                <p className='p-des'>{t('J1 Visa Program for young...')}</p>
                <p className='full-details'>{t('J1 Visa Program for young caregivers between the ages of 18-26. Join Us for an opportunity to grow, learn, and create lifelong bonds across borders!')}</p> 
              </div>
            </div>

            {/* Virtual Internship card */}
            <div className='sec2-card-new' onClick={handleCardClick3}>
              <img src={images.sec2_card_img3}alt="" />
              <div className='sec2-card-del'>
                <p className='p-title'>
                  <span>{t("virtual internship")}</span></p>
                  <p className='p-des'>{t('Project-based virtual position...')}</p>
                  <p className='full-details'>{t('Project-based virtual position guided by a mentor within an American Organization')}</p> 
              </div>
            </div>

            {/*  STEP USA */}
            <div className='sec2-card-new' onClick={handleCardClick4}>
              <img src={images.sec2_card_img4} alt="" />
              <div  className='sec2-card-del'>
                <p className='p-title'>
                  <span>
                  {t("step usa")}
                  </span></p>
                  <p className='p-des'>{t('Short Term Enrichment Program...')}</p>
                  <p className='full-details'>{t('Short Term Enrichment Program (STEP) is a cultural immersion and volunteering program with a tourist visa or ESTA')}</p> 
              </div>
                        
            </div>
          </div>
        </div> 
      </div>
    </div>
    </div>
      
    </div>
  )
}

export default AllProgram2