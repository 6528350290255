import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import i18n from "./constants/i18n";
import { Footer, Navbar } from "./common";
import {
  AboutUs,
  BlogDetail,
  BlogDetail2,
  BlogDetail3,
  Blogs,
  ContactUs,
  Home,
  Testimonials,
} from "./pages";
import TestimonialDetail from "./pages/TestimonialDetail";
import Program from "./pages/Program";
import AllPrograms from "./pages/AllPrograms";
import AllProgram2 from "./pages/Allprogram2";
import AllProgram3 from "./pages/Allprogram3";
import AllProgram4 from "./pages/Allprogram4";
import TaxReturn from "./pages/TaxReturn";
import AuPairProgram from "./pages/AuPairProgram";
import BlogDetailPart4 from "./container/blogDetailPart1/BlogDetailPart4";
import BlogDetail4 from "./pages/BlogDetail4";
import TermsAndconditions from "./pages/Terms&conditions/TermsAndconditions";
import Privacypolicy from "./pages/PrivacyPolicy/Privacypolicy";
import CookiePolicy from "./container/cookiepolicy/CookiePolicy";
import BlogDetail5 from "./pages/BlogDetail5";
import { GlobalStateProvider } from "./GlobalStateContext";
import CookieConsentNew from "./components/cookie/CookieConsentNew";

function App() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };
  return (
    <BrowserRouter >
      <GlobalStateProvider>
        <Navbar />
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/programs" element={<Program />} />
          <Route path="/Testimonials" element={<Testimonials />} />
          <Route path="/Blog" element={<Blogs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/Navigating_the_J1_Visa_Process_Made_Simple_with_USAINTERN.CLUB" element={<BlogDetail />} />
          <Route path="/Conquering_the_Interview:_Essential_Tips_for_Nailing_Your_U.S._Internship" element={<BlogDetail2 />} />
          <Route path="/Mastering_the_Art_of_Remote_Internships:_Tips_for_Success" element={<BlogDetail3 />} />
          <Route path="/Land_Your_Dream_Internship:_Craft_an_Outstanding_U.S._Application" element={<BlogDetail4 />} />
          <Route path="/Navigating_J1-Visa_Taxes:_A_Complete_Guide_To_Filing_Your_U.S._Tax_Return" element={<BlogDetail5 />} />
          <Route path="/TestimonialDetail" element={<TestimonialDetail />} />
          <Route path="/InternshipUSA" element={<AllPrograms />} />
          <Route path="/ProfessionalCareerTrainingUSA" element={<AllProgram2 />} />
          <Route path="/VirtualInternship" element={<AllProgram3 />} />
          <Route path="/STEPUSA" element={<AllProgram4 />} />
          <Route path="/AuPairProgram" element={<AuPairProgram />} />
          <Route path="/TaxReturn" element={<TaxReturn />} />
          <Route path="/Terms&conditions" element={<TermsAndconditions />} />
          <Route path="/PrivacyPolicy" element={<Privacypolicy />} />

          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="/Cookiesentnew" element={<CookieConsentNew />} />
        </Routes>
        
        <Footer />
        <CookieConsentNew/>
      </GlobalStateProvider>
    </BrowserRouter>
  );
}

export default App;
