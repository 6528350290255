import React from 'react'
import "./testimonialbanner.css"
import images from  '../../constants/images'
import { useTranslation } from 'react-i18next';

const Testimonialbanner = () => {
  const{t} = useTranslation();
  return (
    <div>
        <div className="testimonial__main_wrapp">
            <div className="testimonial__text_section_wrapp">
                <div className="testimonial__text_section_inner_wrapp container">
                <p className="large_shadow_text_white">{t("Testimonials")}</p>
                <p className="des_shadow_text_white" style={{ maxWidth: "360px" }}>
                {t("Connect with Us and Let Your")} <br />  {t("Experience Shine.")}
                </p>
                </div>
            </div>
            <img src={images.herowave} alt="wave" className="testimonial__wave_img1" />
            <div className="testimonial__wave_img"></div>
        </div>
    </div>
  )
}

export default Testimonialbanner