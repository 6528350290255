import React, { useEffect, useState } from "react";
import Programbanner from "../container/programBanner/Programbanner";
import Programbanner2 from "../container/programBanner2/Programbanner2";
import ProgramNav from "../container/programNav/ProgramNav";
import ProgramDescription from "../container/programDescription/ProgramDescription";
import "../container/programNav/programnav.css";
import Homecultural from "../container/homeCulturalprgm/Homecultural";
import { useNavigate } from "react-router-dom";
import images from "../constants/images";
import { useTranslation } from "react-i18next";




const Program = () => {
  const [selectedLink, setSelectedLink] = useState("/programs");
  const{t} = useTranslation();
  const handleLinkClick = (link, index) => {
    setSelectedLink(link);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, []);
  const navigate = useNavigate();
  const handleCardClick1 = () => {
      // Navigate to the desired route
      navigate('/InternshipUSA');
    };
    const handleCardClick2 = () => {
      // Navigate to the desired route
      navigate('/ProfessionalCareerTrainingUSA');
    };
    const handleCardClick3 = () => {
      // Navigate to the desired route
      navigate('/VirtualInternship');
    };
    const handleCardClick4 = () => {
      // Navigate to the desired route
      navigate('/STEPUSA');
    };
    const handleCardClick5 = () => {
      // Navigate to the desired route
      navigate('/AuPairProgram');
    };
  return (
    <div>
      <div className='home-section2'>
        <div className='sec2-overlay'>
            {/* <div className='sec2-bg'>
                <img src={images.sec2_bg} alt="" />
            </div> */}
            <div className='home-sec2-content'>
                <div className='h-sec2-head'>
                    <div ></div>
                    <p>{t("programs")}</p>
                </div>
                
                <div className='sec2-cards-div'>
                    {/* internship usa card */}
                    <div className='sec2-card' onClick={handleCardClick1}>
                        <img src={images.sec2_card_img1} alt="" />
                        <div className='sec2-card-del'>
                            <p className='p-title'><span>{t('internship usa')}</span></p>
                            <p className='p-des'>{t('J1 Visa Program for current...')}</p>
                            <p className='full-details'>{t('J1 Visa Program for current university students or recent graduates. Get an internship in your own field!')}</p> 
                        </div>
                    </div>

                    {/* Professional career training USA card */}
                    <div className='sec2-card' onClick={handleCardClick2}>
                        <img src={images.sec2_card_img2} alt="" />
                        <div className='sec2-card-del1'>
                            <p className='p-title'> 
                                <span>
                                {t('professional career training usa')}
                                </span>
                            </p>
                            <p className='p-des'>{t('J1 Visa Program for young...')}</p>
                            <p className='full-details'>{t('J1 Visa Program for young professionals with a diploma in higher education. Get a position is your own career field!')}</p>  
                        </div>    
                    </div>

                    {/* AU Pair usa card */}
                    <div className='sec2-card' onClick={handleCardClick5}>
                        <img src={images.sec2_card_img5} alt="" />
                        <div className='sec2-card-del'>
                            <p className='p-title'><span>{t("au pair usa")}</span></p>
                            <p className='p-des'>{t('J1 Visa Program for young...')}</p>
                            <p className='full-details'>{t('J1 Visa Program for young caregivers between the ages of 18-26. Join Us for an opportunity to grow, learn, and create lifelong bonds across borders!')}</p>  
                        </div>
                    </div>

                    {/* Virtual Internship card */}
                    <div className='sec2-card' onClick={handleCardClick3}>
                        <img src={images.sec2_card_img3}alt="" />
                        <div className='sec2-card-del'>
                            <p className='p-title'>
                                <span>{t("virtual internship")}</span></p>
                                <p className='p-des'>{t('Project-based virtual position...')}</p>
                            <p className='full-details'>{t('Project-based virtual position guided by a mentor within an American Organization')}</p> 
                        </div>
                    </div>

                    {/*  STEP USA */}
                    <div className='sec2-card' onClick={handleCardClick4}>
                        <img src={images.sec2_card_img4} alt="" />
                        <div  className='sec2-card-del'>
                            <p className='p-title'>
                                <span>
                                {t("step usa")}
                                </span></p>
                                <p className='p-des'>{t('Short Term Enrichment Program...')}</p>
                            <p className='full-details'>{t('Short Term Enrichment Program (STEP) is a cultural immersion and volunteering program with a tourist visa or ESTA')}</p> 
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Program;
