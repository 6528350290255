import React, { useRef, useState } from "react";
import "./AboutNewsLetter.css";
import images from "../../constants/images";
import axios from "axios";
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from "react-i18next";

const AboutNewsLetter = () => {
  const [modalopen, setmodalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const{t} = useTranslation();
  const [validation, setValidation] = useState({
    email: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();
  };
  const handleSuccessModal = () => {
    setmodalopen(true);
    // setTimeout(() => {
    //     setmodalopen(false);
    // }, 3000); 
};
  const validateForm = () => {
    const formData = new FormData(formRef.current);
    const newemailValidation = validateEmail(formData.get("email"),t);
    
    setValidation({
        email: newemailValidation,
    });

    if (
        !newemailValidation
      ) {
        setLoading(true);
        const apiFormData = new FormData();
        apiFormData.append("email", formData.get("email"));

        axios
            .post("https://gokulnair.com/usaintern/api/add_newsletter", apiFormData)
            .then((Response) => {
              setLoading(false);
                if (Response.data.status === 1) {
                    console.log("Form submitted successfully!");
                    handleSuccessModal();
                    // Clear form fields
                    formRef.current.reset();
                    setValidation({
                        email: "",
                    });
                } else {
                    console.log("Error:", Response.data.message);
                }
            })
            .catch((err) => {
              setLoading(false);
                console.log("Error:", err);
            });
        }
    };

    const validateEmail = (email, t) => { // Accept t as argument
      console.log("Current language:", t.language);
      if (!email) {
          const errorMessage = t("Email address is required");
          console.log("Error message:", errorMessage);
          return errorMessage;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
          const errorMessage = t("Invalid email address");
          console.log("Error message:", errorMessage);
          return errorMessage;
      }
      return "";
  };
  return (
    <div className="main_wrapp">
      <div className="container about_nl_main_wrapp">
        <div className="nl_first_wrapp">
          <h4>{t("Newsletter")}</h4>
          <p>{t("Be the first one to know about discounts, offers and events")}</p>
        </div>
        <div className="about_nl_input_wrapp">
          <form ref={formRef} className="news-form">
            <div className="news_letter_form">
              <div className="about_nl_input_box_wrapp">
                <img src={images.mail} alt="mail" />
                <input
                  name="email"
                  placeholder={t("Enter your email")}
                  className="about_nl_input_box"
                  type="email"
                />
              </div>
              <button className="about_nl_submit_btn" onClick={handleSubmit}>{t("Submit")}</button>
            </div>
            {validation.email && (
              <div className="val_1_join">{validation.email}</div>
            )}
          </form>
        </div>
      </div>
      {modalopen === true ? (
                <div className="modal_screen">
                    <div className="" style={{ textAlign: "end" }}>
                    <AiOutlineClose
                        className="modal_close"
                        onClick={() => setmodalopen(false)}
                    />
                    </div>
                    <img className="body_img" src={images.check} alt="check" />
                    <h1 className="body_h1">Success</h1>
                </div>
                ) : (
                ""
        )}
        {loading && (
        <div className="loading_screen">
          <div className="loader_contact"></div>
          {/* <img src={images.Spinnerr} alt="Spinner" /> */}
        </div>
      )}
    </div>
  );
};

export default AboutNewsLetter;
