import React from "react";
import "./AboutHero.css";
import images from "../../constants/images";
import { useTranslation } from "react-i18next";

const BlogHero = () => {
  const{t} = useTranslation();
  return (
    <div className="blog_hero_main_wrapp">
      <div className="blog-banner-image"></div>
      <div className="about_hero_text_section_wrapp">
        <div className="about_hero_text_section_inner_wrapp container">
          <p className="large_shadow_text">{t("Blogs")}</p>
          <p className="des_shadow_text" style={{ maxWidth: "360px" }}>
          {t("Find out everything about life in the USA")}<br /> {t("as an exchange visitor.")}
          </p>
        </div>
      </div>
      <img src={images.herowave} alt="blog-wave" className="about_wave_img1" />
      <div className="about_wave_img"></div>
    </div>
  );
};

export default BlogHero;
